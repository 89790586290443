import { extendTheme } from '@chakra-ui/react'
const theme = {
    useSystemColorMode: true,
    // initialColorMode: 'system',
    colors: {
        primary: '#0042da',
        "blue.brand": '#0042da'
    },
    breakpoints: {
        sm: "30em",
        md: "48em",
        lg: "62em",
        xl: "70em",
        xxl: "85em",
        xxxl: "95em",
    },

    fonts: {
        body: "system-ui, sans-serif",
        heading: "Apercu Pro",
        mono: "Menlo, monospace",
    }
}

export default extendTheme(theme)