import axios from "axios";

const API_URL = process.env.GATSBY_API_URL;

class AuthService {
  login(emailToken) {
    return axios
      .get(`${process.env.GATSBY_API_URL}/user/login/${emailToken}` )
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("wbAuth", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    if (typeof window !== 'undefined') {
      return JSON.parse(localStorage.getItem('wbAuth'));
    }else{
      return null
    }
  }

  isAdmin(){

    if (typeof window !== 'undefined' && localStorage.getItem('wbAuth') ) {
      return axios
      .get(`${process.env.GATSBY_API_URL}/user/${JSON.parse(localStorage.getItem('wbAuth')).userId}` )
      .then(response => {
        // console.log('USER DATA', response.data )
        // if (response.data.accessToken) {
        //   localStorage.setItem("wbAuth", JSON.stringify(response.data));
        // }

        return response.data;
      });
    }else{
      return null
    }
    
  }
}

export default new AuthService();