import { Box } from '@chakra-ui/react'
import * as React from 'react'


const Debug = ({ data }) => (

    // document && data ?
    //     <ReactJson
    //         src={data}
    //     />
    //     :


    <Box
        as='pre'
        fontFamily='monospace'
        bgColor='gray.900'
        color='whiteAlpha.700'
        fontFamily='monospace'
        p={4}
        borderRadius={'lg'}

    >
        {JSON.stringify(data, null, 2)}
    </Box >
)

export default Debug