/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import { AdProvider } from "./src/components/ad/adContext"
import { UserProvider } from "./src/components/userContext"

export const wrapRootElement = ({ element }) => (

    <UserProvider>
        <AdProvider>
            {element}
        </AdProvider>
    </UserProvider>
)