import * as React from 'react'
import { createContext, useState } from "react";
import Debug from '../../utils/Debug';

export const AdContext = createContext();

// This context provider is passed to any component requiring the context
export const AdProvider = ({ children }) => {
    const [ad, setAd] = useState('');
    return (
        <AdContext.Provider
            value={{
                ad,
                setAd
            }}
        >
            {children}
        </AdContext.Provider>
    )

}